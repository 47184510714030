<template>
  <div class="view o-hidden">
    <div class="container p-t-2 p-b-3">
      <el-row :gutter="20" type="flex" class="m-l-0 m-r-0">
        <el-col :xs="24">
          <h1 class="font--bigger m-t-0 c-black">
            <span>{{ salutation }}, {{ user.first_name }}</span>
          </h1>
        </el-col>
      </el-row>
    </div>
    <div class="strip-bg strip-bg--blue">
      <div class="container pos-r p-1--sm">
        <el-row :gutter="20" style="top: -2.5rem;" class="m-l-0 m-r-0">
          <el-col :xs="24" :md="12" :lg="6" class="m-b-1">
            <el-card>
              <CardKPI :value="todayWorkHours" :percentage="todayWorkPercentage">
                <template v-slot:footer>
                  <div class="flex">
                    <i class="material-icons">attach_money</i>
                    {{ Number(todayTotalRevenue).toFixed(2) }}
                  </div>
                </template>
              </CardKPI>
            </el-card>
          </el-col>

          <el-col :xs="24" :md="12" :lg="6" class="m-b-1">
            <el-card>
              <CardKPI :value="weekWorkHours" :percentage="weekWorkPercentage">
                <template v-slot:header>Woche</template>
                <template v-slot:footer>
                  <div class="flex">
                    <i class="material-icons">attach_money</i>
                    {{ Number(weekTotalRevenue).toFixed(2) }}
                  </div>
                </template>
              </CardKPI>
            </el-card>
          </el-col>

          <el-col :xs="24" :md="12" :lg="6" class="m-b-1--sm">
            <el-card>
              <CardKPI :value="monthWorkHours" :percentage="monthWorkPercentage">
                <template v-slot:header>{{ format(new Date(), 'MMMM') }}</template>
                <template v-slot:footer>
                  <div class="flex">
                    <i class="material-icons">attach_money</i>
                    {{ Number(monthTotalRevenue).toFixed(2) }}
                  </div>
                </template>
              </CardKPI>
            </el-card>
          </el-col>

          <el-col :xs="24" :md="12" :lg="6">
            <el-card>
              <CardKPI :value="yearWorkHours" :percentage="yearWorkPercentage">
                <template v-slot:header>{{ format(new Date(), 'yyyy') }}</template>
                <template v-slot:footer>
                  <div class="flex">
                    <i class="material-icons">attach_money</i>
                    {{ Number(yearTotalRevenue).toFixed(2) }}
                  </div>
                </template>
              </CardKPI>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="container m-t-2 p-b-half p-1--sm m-b-1">
      <!-- Chart -->
      <el-row :gutter="20" class="m-l-0 m-r-0">
        <!-- Chart 1 -->
        <el-col :xs="24" :lg="12">
          <h1 class="font--big m-t-0 c-black">
            <span>Arbeitsaufwand</span>
          </h1>
          <el-card>
            <ChartWorkload v-if="currentUserId" />
          </el-card>
        </el-col>

        <!-- Chart 2 -->
        <el-col :xs="24" :lg="12" class="m-t-1--sm">
          <h1 class="font--big m-t-0 c-black">
            <span>Umsatz</span>
          </h1>
          <el-card class="card--chart" v-loading="!incomeChartsDataLoaded">
            <BarChart
              :chart-data="incomeChartsData"
              :options="barChartOptions"
              :width="charts.width"
              :height="charts.height"
              v-if="incomeChartsDataLoaded"
            />
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import { format } from 'date-fns';
import BarChart from '@/components/Chart/BarChart';
import CardKPI from '@/components/Card/CardKPI';
import ChartWorkload from '@/components/Chart/ChartWorkload';
import FormTimetracking from '@/components/Form/FormTimetracking';
import FormTravelTracking from '@/components/Form/FormTravelTracking';

export default {
  name: 'view-dashboard',

  components: {
    BarChart,
    CardKPI,
    ChartWorkload,
    FormTimetracking,
    FormTravelTracking
  },

  computed: {
    ...mapGetters({
      auth: 'auth/auth',
      charts: 'report/charts',
      currentMonthWorkloadTargetFigures: 'targetFigures/currentMonthWorkloadTargetFigures',
      currentYearWorkloadTargetFigures: 'targetFigures/currentYearWorkloadTargetFigures',
      incomeChartsData: 'report/incomeChartsData',
      incomeChartsDataLoaded: 'report/incomeChartsDataLoaded',
      monthWorkHours: 'tracking/monthWorkHours',
      monthWorkTracking: 'tracking/monthWorkTracking',
      monthTotalRevenue: 'tracking/monthTotalRevenue',
      todayWorkHours: 'tracking/todayWorkHours',
      todayWorkPercentage: 'tracking/todayWorkPercentage',
      todayWorkTracking: 'tracking/todayWorkTracking',
      todayTotalRevenue: 'tracking/todayTotalRevenue',
      tracking: 'tracking/all',
      user: 'user/currentUser',
      weekWorkPercentage: 'tracking/weekWorkPercentage',
      weekWorkTracking: 'tracking/weekWorkTracking',
      weekWorkHours: 'tracking/weekWorkHours',
      weekTotalRevenue: 'tracking/weekTotalRevenue',
      yearWorkHours: 'tracking/yearWorkHours',
      yearWorkTracking: 'tracking/yearWorkTracking',
      yearTotalRevenue: 'tracking/yearTotalRevenue'
    }),

    currentUserId() {
      return get(this.user, 'id');
    },

    monthWorkPercentage() {
      let result = 0;
      if (this.monthWorkHours && this.currentMonthWorkloadTargetFigures) {
        result = Math.round((this.monthWorkHours * 100) / this.currentMonthWorkloadTargetFigures);
      }
      return result;
    },

    salutation() {
      const greetings = ['Guten Morgen', 'Guten Tag', 'Guten Abend'];
      let result = greetings[1];
      const currentHour = new Date().getHours();
      if (currentHour < 11) {
        result = greetings[0];
      } else if (currentHour > 17) {
        result = greetings[2];
      }

      return result;
    },

    yearWorkPercentage() {
      let result = 0;
      if (this.yearWorkHours && this.currentYearWorkloadTargetFigures) {
        result = Math.round((this.yearWorkHours * 100) / this.currentYearWorkloadTargetFigures);
      }
      return result;
    }
  },

  data() {
    return {
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        }
      },
      cards: [
        {
          title: 'Heute',
          value: 0,
          percentage: 0
        },
        {
          title: 'Woche',
          value: 0,
          percentage: 0
        },
        {
          title: 'Mai',
          value: 0,
          percentage: 0
        },
        {
          title: '2019',
          value: 0,
          percentage: 0
        }
      ]
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    format,

    init() {
      this.$store.dispatch('report/GET_INCOME_CHART_PER_MONTH');

      if (this.currentUserId) {
        const year = new Date().getFullYear();
        this.$store.dispatch('tracking/GET_TIMETRACKER', {
          filter: {
            start: {
              _gte: `${year}-01-01 00:00:00`
            }
          }
        });
      }
    }
  },

  watch: {
    currentUserId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.init();
      }
    }
  }
};
</script>
