<template>
  <el-form ref="form" @submit.native.prevent="onSubmit" class="w-100">
    <!-- Company -->
    <el-form-item label="Firma">
      <select v-model="form.company" class="w-100" @change="onChangeFormInput">
        <option :value="company.id" v-for="company in companies" :key="company.id">{{ company.name }}</option>
      </select>
    </el-form-item>

    <!-- Project -->
    <el-form-item label="Projekt" prop="project">
      <select v-model="form.project" class="w-100" @change="onChangeFormInput">
        <option :value="project.id" v-for="project in projects" :key="project.id">{{ project.name }}</option>
      </select>
    </el-form-item>

    <!-- Date -->
    <el-form-item label="Datum">
      <el-input v-model="form.date" type="date" placeholder="dd.MM.YYYY" @change="onChangeFormInput"></el-input>
    </el-form-item>

    <!-- Comment -->
    <el-form-item label="Kommentar">
      <el-input type="textarea" :rows="4" placeholder="Kommentar" v-model="form.description" @change="onChangeFormInput"></el-input>
    </el-form-item>

    <!-- Submit -->
    <div class="m-t-2 m-b-0 flex">
      <el-button type="info" plain @click.prevent="clearForm" class="w-100">Zurücksetzen</el-button>
      <el-button type="primary" native-type="submit" class="w-100" :loading="loading">Reise erfassen</el-button>
    </div>
  </el-form>
</template>

<script>
import { addDays, format } from 'date-fns';

export default {
  name: 'FormTravelTracking',

  data() {
    return {
      form: {
        description: '',
        company: 0,
        date: null,
        project: 0
      },
      loading: false
    };
  },

  computed: {
    companies() {
      return this.$store.state.company.all;
    },

    persistentForm() {
      return this.$store.state.travel.form;
    },

    projects() {
      return this.$store.state.project.all;
    },

    user() {
      return this.$store.state.user.current;
    }
  },

  mounted() {
    this.init();

    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'travel/SET_FORMULAR':
          if (this.form !== state.travel.form) {
            Object.assign(this.form, state.travel.form);
          }
      }
    });
  },

  methods: {
    format,

    clearForm() {
      this.form.project = null;
      this.form.company = null;
      this.form.description = null;
      this.form.date = format(new Date(), 'yyyy-MM-dd');

      this.onChangeFormInput();
    },

    init() {
      Object.assign(this.form, this.persistentForm);
      this.form.date = format(new Date(), 'yyyy-MM-dd');
    },

    onChangeFormInput() {
      this.$store.commit('travel/SET_FORMULAR', this.form);
    },

    onSubmit() {
      const data = Object.assign({}, this.form);
      data.date = format(addDays(new Date(this.form.date), 1), 'yyyy-MM-dd');
      data.user = this.user.id;

      this.loading = true;

      this.$store
        .dispatch('travel/ADD_TRAVELING', data)
        .then(() => {
          this.loading = false;
          this.$notify({
            title: 'Erfolg',
            message: `Reisekosten wurde erfolgreich hinzugefügt.`,
            type: 'success',
            duration: 6000
          });

          this.clearForm();
        })
        .catch(err => {
          this.loading = false;
          const errorText = err.response.data.message || err.message;
          this.$notify({
            title: 'Fehler',
            message: `${err.response.status}: ${errorText}`,
            type: 'error',
            duration: 8000
          });
        });
    }
  }
};
</script>
